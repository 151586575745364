import { styled } from '@mui/system'
import Icon from '../../atoms/icon'
import { ScrollbarStyled } from '../../particles/slider/slider.styled'

const shouldForwardProp = (prop) =>
	!['action', 'controlsPosition', 'pagination', 'navigation', 'titleComponent'].includes(prop)

const Root = styled('div', { shouldForwardProp })`
	--swiper-navigation-size: 0px;
	${({ theme }) => theme.breakpoints.up('sm')} {
		${({ controlsPosition, navigation }) =>
			controlsPosition === 'default' && navigation
				? `
						& > div > div:not(.swiper-pagination) {
							padding-left: 0;
							padding-right: 0;
						}
				  `
				: `
						--swiper-navigation-size: 0;
				  `}
		${({ controlsPosition, scrollbar, navigation }) =>
			controlsPosition === 'default' && scrollbar
				? navigation
					? `--swiper-scrollbar-bottom: -30px; --swiper-scrollbar-sides-offset: calc(var(--swiper-navigation-size) + 4.8%);`
					: `--swiper-scrollbar-bottom: -30px;`
				: null}
	}

	.swiper-pagination {
		padding: 33px 16px 0;
		${({ theme }) => theme.breakpoints.up('sm')} {
			padding: ${({ controlsPosition, theme }) =>
				controlsPosition === 'default' ? 'calc(' + theme.spacing(0.5) + ' + 3.3%) 0 0' : theme.spacing(0, 2)};
		}
		position: relative;
		left: 0;
		transform: none;
	}
`

const TopContainer = styled('div', { shouldForwardProp })`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: ${({ titleComponent }) => (titleComponent ? 'space-between' : 'center')};
	padding-bottom: 3.85%;
	${({ theme }) => theme.breakpoints.up('sm')} {
		flex-direction: row;
	}
`

const ControlsContainer = styled('div')`
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
	line-height: 0;
	${({ theme }) => theme.breakpoints.up('sm')} {
		justify-content: flex-end;
		width: auto;
	}
	.swiper-button-prev,
	.swiper-button-next {
		position: static;
	}
`

const NavButton = styled(Icon, { shouldForwardProp })`
	&.swiper-button-prev,
	&.swiper-button-next {
		border: 1px solid transparent;
		border-radius: 50%;
		color: ${({ theme }) => theme.palette.primary.main};
		font-size: ${({ theme }) => theme.typography.pxToRem(29)};
		padding: 13px;
	}
	${({ controlsPosition }) =>
		controlsPosition === 'top-right'
			? `
			margin-top: 0;
		`
			: null}
	${({ action, pagination, theme }) =>
		!pagination
			? action === 'prev'
				? `
						margin-right: ${theme.spacing(1.5)};
				  `
				: `
						margin-left: ${theme.spacing(1.5)};
				  `
			: null}
`

const Pagination = styled('div', { shouldForwardProp })`
	padding: ${({ controlsPosition, theme }) =>
		controlsPosition === 'default' ? 'calc(' + theme.spacing(0.5) + ' + 3.3%) 0 0' : theme.spacing(0, 2)};
	position: relative;
	.swiper-pagination-bullet {
		--swiper-pagination-bullet-horizontal-gap: 8px;
		background-color: transparent;
		height: auto;
		margin: 0 var(--swiper-pagination-bullet-horizontal-gap);
		opacity: 1;
		padding: 0;
		width: auto;
		&:before {
			background-color: transparent;
			border: 1px solid var(--color-primary);
			border-radius: 50%;
			content: '';
			display: block;
			height: ${({ theme }) => theme.spacing('6px')};
			width: ${({ theme }) => theme.spacing('6px')};
		}
		&-active {
			cursor: default;
			&:before {
				background-color: var(--color-primary);
			}
		}
	}
`

const Scrollbar = styled(ScrollbarStyled, { shouldForwardProp })`
	&.swiper-scrollbar {
		position: static;
		min-width: 200px;
	}
`

export { ControlsContainer, NavButton, Pagination, Root, TopContainer, Scrollbar }
